import React from "react"
import { setDPI } from "./utils"
import Poster from "../poster/Poster"
import URLState from "../utils/URLState"

import { baseSize, defaultState } from "../poster/data"

class PosterPrint extends React.Component {
  componentWillMount() {
    // This seems to be too high and is causing issues
    const dpi = Math.max(this.props.dpi, (this.props.dpi * this.scale) / 2)
    setDPI(dpi)
  }

  get scale() {
    const { inches, dpi } = this.props
    const size = (parseInt(inches, 10) * dpi) / 2 // (96 * window.devicePixelRatio)
    const scale = size / baseSize
    return scale
  }

  render() {
    const { size } = this.props
    const height = size === "18x24" ? baseSize * (24 / 18) : baseSize
    return (
      <Poster
        {...this.props}
        width={baseSize}
        height={height}
        scale={this.scale}
        interactive={false}
        transitionIn={false}
        animateMove={false}
        overlayImage={true}
        print={true}
        readonly
      />
    )
  }
}

PosterPrint.defaultProps = {
  dpi: 300,
  inches: 18
}

const PosterPrintContainer = props => {
  return (
    <URLState initialState={defaultState}>
      {state => {
        return <PosterPrint {...state} />
      }}
    </URLState>
  )
}

export default PosterPrintContainer
