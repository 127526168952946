import React from "react"
import queryString from "query-string"
import { Base64 } from "js-base64"
import { debounce } from "lodash"
import { Location } from "@reach/router"

const withRouter = Component => rootProps => {
  return (
    <Location>
      {locationProps => {
        return <Component {...rootProps} {...locationProps} />
      }}
    </Location>
  )
}

class URLState extends React.Component {
  constructor(props) {
    super(props)
    this._unmounted = false
    this.state = props.defaultState || {}

    if (typeof window !== `undefined`) {
      window._updateState = this.updateState
    }
  }

  componentWillUnmount = () => {
    this._unmounted = true
  }

  componentWillMount() {
    // Use the URL
    if (typeof window !== "undefined") {
      let state = queryString.parse(window.location.search) || {}

      if (state._v) {
        state = JSON.parse(Base64.decode(state._v))
      } else {
        state = this.props.defaultState
      }
      this.updateState(state)
    }
  }

  get queryString() {
    return this.props.base64
      ? `_v=${Base64.encode(JSON.stringify(this.getValues(this.state)))}`
      : queryString.stringify(this.getValues(this.state)).replace(/\./g, "%2E")
  }

  getValues = state => {
    delete state["ref"]
    return state
  }

  updateHistory = () => {
    if (typeof window === "undefined") return
    let url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    url = `${url}?${this.queryString}`
    window.history.pushState({ path: url }, "", url)
  }

  updateState = state => {
    if (this._unmounted) return
    let nextState = this.state
    if (typeof state === "string") {
      nextState = JSON.parse(Base64.decode(state))
    } else {
      nextState = this.getValues({ ...state })
    }

    if (typeof window !== `undefined`) window._urlState = nextState

    this.setState(nextState, debounce(this.updateHistory, 300))
  }

  render() {
    return this.props.children(this.state, this.updateState, this.queryString)
  }
}

URLState.defaultProps = {
  strict: false,
  base64: true
}

export default withRouter(URLState)
